// HistoryModal.js
import React from 'react';
import { Modal, StyleSheet, Text, View, FlatList, Button, ScrollView } from 'react-native';

const fieldTranslations = {
    txID: 'Хеш',
    amount: 'Сумма',
    currency: 'Валюта',
    from: 'Отправитель',
    to: 'Получатель',
    commission: 'Комиссия',
    commissionCoin: 'Валюта комиссии',
    message: 'Сообщение',
    coinIn: 'Входящая валюта',
    coinOut: 'Исходящая валюта',
    amountIn: 'Количество входящей валюты',
    amountOut: 'Количество исходящей валюты',
    price: 'Цена',
    coin: 'Валюта',

    withdrawal: 'Вывод',
    deposit: 'Депозит',
    exchange: 'Обмен',
    delegation: 'Делегирование',
    incomeStaking: 'Начисление за стейкинг'
};

const HistoryModal = ({ visible, onClose, operations }) => {
    const renderOperation = ({ item }) => (
        <View style={styles.operationItem}>
            <Text style={styles.operationType}>Тип операции: {fieldTranslations[item.type]}</Text>
            <Text style={styles.operationDate}>Дата: {new Date(item.timestamp).toLocaleString()}</Text>
            <Text style={styles.operationDate}>Данные:</Text>
            <ScrollView style={styles.dataContainer}>
                {Object.keys(item.data).map((key) => {
                    const translatedKey = fieldTranslations[key] || key;  // Используем расшифровку или оставляем оригинальное название
                    return (
                        <Text key={key} style={styles.dataText}>
                            {translatedKey}: {item.data[key]}
                        </Text>
                    );
                })}
            </ScrollView>
        </View>
    );

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.modalBackground}>
                <View style={styles.modalContainer}>
                    <FlatList
                        data={operations}
                        renderItem={renderOperation}
                        keyExtractor={(item) => item._id}
                        style={styles.flatList}
                    />
                    <Button title="Закрыть" onPress={onClose} style={styles.closeButton} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        width: '80%',
        maxHeight: '90%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    flatList: {
        maxHeight: '90%',
        marginBottom: 10,
    },
    operationItem: {
        marginBottom: 15,
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
    },
    dataContainer: {
        maxHeight: 100,
        paddingVertical: 5,
        borderWidth: 1,
        borderColor: '#ddd',
        marginTop: 5,
    },
    dataText: {
        fontSize: 14,
        marginBottom: 5,
    },
    operationType: {
        fontWeight: 'bold',
    },
    operationDate: {
        color: '#888',
    },
    closeButton: {
        marginTop: 10,
    },
});

export default HistoryModal;