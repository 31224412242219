import React from 'react';
import { Modal, View, Text, Button, Image, StyleSheet } from 'react-native';
import Toast from 'react-native-toast-message'; // если используете для уведомлений

const ProductDetailsModal = ({ modalVisible, selectedItem, closeModal, buyProduct, inactiveProduct, userId }) => {
    if (!selectedItem) return;

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={closeModal}
        >
            <View style={styles.detailsModalContainer}>
                <View style={styles.detailsModalContent}>
                    <Image source={{ uri: selectedItem?.imageUrl }} style={styles.detailsImage} />
                    <Text style={styles.detailsTitle}>{selectedItem?.name}</Text>
                    <Text style={styles.detailsPrice}>
                        {selectedItem?.price} {selectedItem?.currency}
                    </Text>
                    <Text style={styles.detailsDescription}>{selectedItem?.description}</Text>

                    <View style={styles.buttonContainer}>
                        <Button title="Закрыть" onPress={closeModal} color="red" />
                        {selectedItem.userId !== userId ? (
                            <Button title="Купить" onPress={() => buyProduct(selectedItem?.productId)} />
                        ) : (
                                <Button title="Деактивировать" onPress={() => inactiveProduct(selectedItem?.productId)} />
                        )}
                    </View>
                </View>
                <Toast style={{ zIndex: 9999 }} />
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    detailsModalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    detailsModalContent: {
        backgroundColor: '#fff',
        borderRadius: 8,
        width: '90%',
        padding: 20,
        alignItems: 'center',
    },
    detailsImage: {
        width: 100,
        height: 100,
        borderRadius: 10,
        marginBottom: 10,
    },
    detailsTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    detailsPrice: {
        fontSize: 16,
        color: 'green',
        marginBottom: 10,
    },
    detailsDescription: {
        fontSize: 14,
        color: 'gray',
        marginBottom: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
});

export default ProductDetailsModal;