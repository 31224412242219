import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Modal, View, Text, TextInput, StyleSheet, TouchableOpacity, Picker, Dimensions, Platform } from 'react-native';

const { height, width } = Dimensions.get('window');

// Динамическое условие для маленьких экранов
const isSmallScreen = height < 1000;

const validationSchema = Yup.object().shape({
    type: Yup.string().required('Выберите тип товара'),
    name: Yup.string().required('Введите название товара')
        .max(12, 'Описание не должно превышать 12 символов'),
    price: Yup.number()
        .typeError('Цена должна быть числом')
        .positive('Цена должна быть больше нуля')
        .required('Введите цену'),
    currency: Yup.string().required('Выберите валюту'),
    paymentMethod: Yup.string().required('Выберите метод оплаты'),
    requisites: Yup.string().required('Введите реквизиты'),
    description: Yup.string()
        .required('Описание должно превышать 10 символов')
        .max(300, 'Описание не должно превышать 300 символов'),
    product: Yup.string().required('Укажите ваш товар!'),
});

const SellModal = ({ visible, onClose, onSell, categories }) => {
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Formik
                        initialValues={{
                            type: '',
                            name: '',
                            price: '',
                            currency: '',
                            paymentMethod: '',
                            requisites: '',
                            description: '',
                            product: '',
                            imageUrl: "https://bazerbot.com/images/bazer.png",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            onSell(values);
                        }}
                    >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                            <>
                                <Text style={styles.modalTitle}>Создать товар</Text>
                                <Text>
                                    Размер екрана: {width} на {height}
                                </Text>
                                <View style={styles.field}>
                                    <Text style={styles.label}>Тип товара:</Text>
                                    <Picker
                                        selectedValue={values.type}
                                        onValueChange={(value) => setFieldValue('type', value)}
                                        style={styles.input}
                                    >
                                        <Picker.Item label="Выберите тип" value="" />
                                        {categories.map((category) => (
                                            <Picker.Item label={category} value={category} key={category} />
                                        ))}
                                    </Picker>
                                    <Text style={styles.errorText}>
                                        {touched.type && errors.type ? errors.type : ' '}
                                    </Text>
                                </View>

                                <View style={styles.field}>
                                    <Text style={styles.label}>Название:</Text>
                                    <TextInput
                                        placeholder="Название"
                                        value={values.name}
                                        onChangeText={handleChange('name')}
                                        onBlur={handleBlur('name')}
                                        style={styles.input}
                                    />
                                    <Text style={styles.errorText}>
                                        {touched.name && errors.name ? errors.name : ' '}
                                    </Text>
                                </View>

                                <View style={styles.row}>
                                    <View>
                                        <Text style={styles.label}>Цена:</Text>
                                        <TextInput
                                            placeholder="Цена"
                                            value={values.price}
                                            onChangeText={handleChange('price')}
                                            onBlur={handleBlur('price')}
                                            keyboardType="numeric"
                                            style={styles.input}
                                        />
                                        <Text style={styles.errorText}>
                                            {touched.price && errors.price ? errors.price : ' '}
                                        </Text>
                                    </View>
                                    <View>
                                        <Text style={styles.label}>Валюта:</Text>
                                        <Picker
                                            selectedValue={values.currency}
                                            onValueChange={(value) => setFieldValue('currency', value)}
                                            style={styles.input}
                                        >
                                            <Picker.Item label="Валюта" value="" />
                                            {['UAH', 'RUB', 'USDT', 'TRON'].map((currency) => (
                                                <Picker.Item label={currency} value={currency} key={currency} />
                                            ))}
                                        </Picker>
                                        <Text style={styles.errorText}>
                                            {touched.currency && errors.currency ? errors.currency : ' '}
                                        </Text>
                                    </View>
                                </View>

                                <View style={styles.field}>
                                    <Text style={styles.label}>Метод оплаты:</Text>
                                    <Picker
                                        selectedValue={values.paymentMethod}
                                        onValueChange={(value) => setFieldValue('paymentMethod', value)}
                                        style={styles.input}
                                    >
                                        <Picker.Item label="Метод оплаты" value="" />
                                        {['mono', 'trc20', 'privat24'].map((method) => (
                                            <Picker.Item label={method} value={method} key={method} />
                                        ))}
                                    </Picker>
                                    <Text style={styles.errorText}>
                                        {touched.paymentMethod && errors.paymentMethod ? errors.paymentMethod : ' '}
                                    </Text>
                                </View>

                                <View style={styles.field}>
                                    <Text style={styles.label}>Реквизиты:</Text>
                                    <TextInput
                                        placeholder="Реквизиты"
                                        value={values.requisites}
                                        onChangeText={handleChange('requisites')}
                                        onBlur={handleBlur('requisites')}
                                        style={styles.input}
                                    />
                                    <Text style={styles.errorText}>
                                        {touched.requisites && errors.requisites ? errors.requisites : ' '}
                                    </Text>
                                </View>

                                <View style={styles.field}>
                                    <Text style={styles.label}>Описание:</Text>
                                    <TextInput
                                        placeholder="Описание"
                                        value={values.description}
                                        onChangeText={handleChange('description')}
                                        onBlur={handleBlur('description')}
                                        style={styles.input}
                                    />
                                    <Text style={styles.errorText}>
                                        {touched.description && errors.description ? errors.description : ' '}
                                    </Text>
                                </View>

                                <View style={styles.field}>
                                    <Text style={styles.label}>Товар:</Text>
                                    <TextInput
                                        placeholder="Товар"
                                        value={values.product}
                                        onChangeText={handleChange('product')}
                                        onBlur={handleBlur('product')}
                                        style={styles.input}
                                    />
                                    <Text style={styles.errorText}>
                                        {touched.product && errors.product ? errors.product : ' '}
                                    </Text>
                                </View>

                                <View style={styles.buttonContainer}>
                                    <TouchableOpacity onPress={onClose} style={styles.buttonClose}>
                                        <Text style={styles.buttonText}>Отмена</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={handleSubmit} style={styles.buttonSubmit}>
                                        <Text style={styles.buttonText}>Выставить</Text>
                                    </TouchableOpacity>
                                </View>
                            </>
                        )}
                    </Formik>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        borderRadius: 10,
        width: '90%',
        maxHeight: isSmallScreen ? '100%' : '90%',
        padding: isSmallScreen ? 10 : 16,
    },
    modalTitle: {
        fontSize: isSmallScreen ? 10 : 18,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: isSmallScreen ? 5 : 10,
    },
    field: {
        marginBottom: isSmallScreen ? 2 : 8,
    },
    row: {
        flexDirection: isSmallScreen ? 'row' : 'column',
        justifyContent: 'space-between',
    },
    label: {
        fontSize: isSmallScreen ? 10 : 14,
        marginBottom: 4,
    },
    input: {
        height: isSmallScreen ? 30 : 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 6,
        paddingHorizontal: 8,
        fontSize: isSmallScreen ? 10 : 15,
    },
    errorText: {
        fontSize: isSmallScreen ? 8 : 12,
        color: 'red',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: isSmallScreen ? 0 : 10,
        width: '100%',
        paddingHorizontal: isSmallScreen ? 5 : 10,
    },
    buttonSubmit: {
        backgroundColor: '#007BFF',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonClose: {
        backgroundColor: 'red',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        fontSize: isSmallScreen ? 10 : 15
    }
});

export default SellModal;