import React from 'react';
import { View, Text, Modal, Button, Picker, StyleSheet } from 'react-native';

const FilterModal = ({ visible, onClose, selectedCategory, setSelectedCategory, showMyItems, setShowMyItems }) => {
    const categories = ["staking-share"]; // Пример категорий

    return (
        <Modal
            visible={visible}
            animationType="slide"
            transparent={true}
            onRequestClose={onClose}
        >
            <View style={styles.modalOverlay}>
                <View style={styles.modalContent}>
                    <Text style={styles.label}>Тип товара:</Text>
                    <Picker
                        selectedValue={selectedCategory}
                        onValueChange={(value) => setSelectedCategory(value)}
                        style={styles.input}
                    >
                        <Picker.Item label="Выберите тип" value="" />
                        {categories.map((category) => (
                            <Picker.Item label={category} value={category} key={category} />
                        ))}
                    </Picker>

                    <Text style={styles.label}>Показывать мои объявления:</Text>
                    <Button
                        title={showMyItems ? 'Мои объявления' : 'Все объявления'}
                        onPress={() => setShowMyItems(!showMyItems)} // Переключаем состояние
                    />

                    <View style={styles.modalButtons}>
                        <Button title="Закрыть" onPress={onClose} color={"red"} />
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 8,
        width: '80%',
    },
    label: {
        fontSize: 16,
        marginBottom: 10,
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 8,
        marginBottom: 10,
    },
    modalButtons: {
        justifyContent: 'center',
        marginTop: 20,
    },
});

export default FilterModal;