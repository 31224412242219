import React from 'react';
import QRCode from 'react-native-qrcode-svg';
import Toast from 'react-native-toast-message';
import { Modal, View, Text, Button, StyleSheet } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';

const ReplenishModal = ({ visible, onClose, replenishInfo }) => {
    const showToastSuccess = (text) =>
        Toast.show({
            type: 'success',
            text1: text,
        });

    const copyToClipboard = () => {
        Clipboard.setString(replenishInfo.address);
        showToastSuccess("Скопировано в буфер обмен!");
    };

    const copyToClipboardMemo = () => {
        Clipboard.setString(replenishInfo.memo);
        showToastSuccess("Скопировано в буфер обмен!");
    };

    return (
        <Modal
            visible={visible}
            animationType="fade"
            transparent={true}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.modalTitle}>Пополнить</Text>
                    <View style={styles.qrCodeContainer}>
                        <QRCode value={replenishInfo.address} size={150} />
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.addressText}>{replenishInfo.address}</Text>
                        <Button title="Копировать адрес" onPress={copyToClipboard} />
                    </View>
                    <Text style={styles.replenishInfoText}>
                        Суммы менее {replenishInfo.minAmount} {replenishInfo.coin} не будут зачислены!
                    </Text>
                    {replenishInfo.memo !== null && (
                        <View style={styles.infoContainer}>
                            <Text style={styles.replenishInfoText}>
                                Пополнение без memo: {replenishInfo.memo} не будут зачислены!
                            </Text>
                            <Button title="Копировать memo" onPress={copyToClipboardMemo} />
                        </View>
                    )}
                    <Button title="Закрыть" onPress={onClose} style={styles.closeButton} color="red" />
                </View>
                <Toast style={{ zIndex: 9999 }} />
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        width: '90%', // Ограничиваем ширину
        maxWidth: 400, // Максимальная ширина
        alignItems: 'center', // Выравниваем элементы по центру
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 15,
        textAlign: 'center',
    },
    qrCodeContainer: {
        alignItems: 'center',
        marginVertical: 15,
    },
    infoContainer: {
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
        padding: 10,
        width: '100%',
        marginBottom: 15,
    },
    addressText: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 10,
    },
    replenishInfoText: {
        fontSize: 14,
        color: '#333',
        textAlign: 'center',
        marginBottom: 15,
    },
    closeButton: {
        marginTop: 10,
    },
});

export default ReplenishModal;