
import * as Yup from 'yup';
import { Formik } from 'formik';
import React, { useState } from 'react';
import BazerService from '../api/bazer/BazerService';
import { Modal, View, Text, TextInput, Button, StyleSheet, Picker } from 'react-native';

// Пример функции получения комиссии
const getCommission = async (seedPhrase, currency) => {
    const listCommissions = await BazerService.listCommissions(seedPhrase);
    const commissionData = listCommissions.data.list[currency];
    return commissionData ? `${commissionData.amount} ${commissionData.currencyCommissionPayment}` : `0 ${currency}`;
};

const currencyOptions = [{
    id: 0,
    name: "Вывести через blockchain",
},
{
    id: 1,
    name: "Вывести в сети bazer bot(без комисии)",
}
];

const SendModal = ({ visible, onClose, seedPhrase, currency, handleSendCoin }) => {
    const [commission, setCommission] = useState(0);

    // Валидация с Yup
    const validationSchema = Yup.object({
        transferType: Yup.string().required("Выберите тип перевода"),
        recipient: Yup.string()
            .required("Введите адрес"),
        amount: Yup.number()
            .required("Введите сумму")
            .min(0.01, "Сумма должна быть больше 0"),
        comment: Yup.string().max(100, "Комментарий слишком длинный"),
    });

    return (
        <Modal
            visible={visible}
            animationType="fade"
            transparent={true}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.modalTitle}>Отправка перевода</Text>

                    <Formik
                        initialValues={{
                            transferType: "",
                            recipient: "",
                            amount: "",
                            comment: "",
                            currency,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            handleSendCoin(values)
                            onClose();
                        }}
                    >
                        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                            <View>
                                {/* Тип перевода */}
                                <Text style={styles.label}>Тип перевода</Text>
                                <Picker
                                    selectedValue={values.transferType}
                                    onValueChange={(itemValue) => {
                                        handleChange("transferType")(itemValue);
                                        getCommission(seedPhrase, currency).then(setCommission);
                                    }}
                                    style={styles.input}
                                >
                                    <Picker.Item label="Выберите тип" value="" />
                                    {currencyOptions.map((option) => (
                                        <Picker.Item key={option.id} label={option.name} value={option.id} />
                                    ))}
                                </Picker>
                                {touched.transferType && errors.transferType && (
                                    <Text style={styles.error}>{errors.transferType}</Text>
                                )}

                                {/* Адрес получателя */}
                                <Text style={styles.label}>Адрес получателя</Text>
                                <TextInput
                                    style={styles.input}
                                    onChangeText={handleChange("recipient")}
                                    onBlur={handleBlur("recipient")}
                                    value={values.recipient}
                                    placeholder="Введите адрес"
                                />
                                {touched.recipient && errors.recipient && (
                                    <Text style={styles.error}>{errors.recipient}</Text>
                                )}

                                {/* Сумма */}
                                <Text style={styles.label}>Сумма</Text>
                                <TextInput
                                    style={styles.input}
                                    onChangeText={handleChange("amount")}
                                    onBlur={handleBlur("amount")}
                                    value={values.amount}
                                    keyboardType="numeric"
                                    placeholder="Введите сумму"
                                />
                                {touched.amount && errors.amount && (
                                    <Text style={styles.error}>{errors.amount}</Text>
                                )}

                                {/* Комиссия */}
                                <Text style={styles.label}>
                                    Комиссия за перевод: {commission || "рассчитывается..."}
                                </Text>

                                {/* Комментарий */}
                                <Text style={styles.label}>Комментарий</Text>
                                <TextInput
                                    style={styles.input}
                                    onChangeText={handleChange("comment")}
                                    onBlur={handleBlur("comment")}
                                    value={values.comment}
                                    placeholder="Введите комментарий (необязательно)"
                                />
                                {touched.comment && errors.comment && (
                                    <Text style={styles.error}>{errors.comment}</Text>
                                )}

                                {/* Кнопки */}
                                <View style={styles.buttonContainer}>
                                    <Button title="Отмена" onPress={onClose} color="red" />
                                    <Button title="Отправить" onPress={handleSubmit} />
                                </View>
                            </View>
                        )}
                    </Formik>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxWidth: 400,
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 15,
        textAlign: 'center',
    },
    label: {
        fontSize: 14,
        marginBottom: 5,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
    },
    error: {
        fontSize: 12,
        color: 'red',
        marginBottom: 5,
    },
    buttonContainer: {
        marginTop: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default SendModal;