import React, { useState, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { StyleSheet, Text, View, FlatList, Button } from 'react-native';
import BazerService from '../api/bazer/BazerService.js';
import ReplenishModal from '../component/ReplenishModal.js';
import SendModal from '../component/SendModal.js';
import Loader from '../component/Loader.js';
import Toast from 'react-native-toast-message';
import HistoryModal from '../component/HistoryModal.js';  // Добавим компонент модального окна для истории

export const CabinetScreen = ({ seedPhrase }) => {
    const [loading, setLoading] = useState(true);
    const [coinsData, setCoinsData] = useState([]);
    const [showSendModal, setShowSendModal] = useState(false);
    const [showReplenishModal, setShowReplenishModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);  // Состояние для отображения истории
    const [replenishInfo, setReplenishInfo] = useState({ coin: '', minAmount: 0, address: '', memo: null });
    const [userOperations, setUserOperations] = useState([]); // Состояние для операций

    useFocusEffect(
        useCallback(() => {
            refreshData();
        }, [])
    );

    const getBalanceData = async () => {
        try {
            const response = await BazerService.BalanceList(seedPhrase);
            setCoinsData(response.data);
        } catch (error) {
            console.error('Error coins data:', error);
        } finally {
            setLoading(false);
        }
    };

    const refreshData = async () => {
        setLoading(true);
        await getBalanceData();
    };

    const handleReplenish = async (coinName) => {
        const findCoin = coinsData.find(e => e.name === coinName);
        setReplenishInfo({
            coin: coinName,
            minAmount: findCoin.minAmount,
            address: findCoin.address,
            memo: findCoin.memo,
        });
        setShowReplenishModal(true);
    };

    const handleCloseReplenishModal = () => {
        setShowReplenishModal(false);
    };

    const handleSend = async (coinName) => {
        const findCoin = coinsData.find(e => e.name === coinName);
        setReplenishInfo({
            coin: coinName,
            minAmount: findCoin.minAmount,
            address: findCoin.address,
            memo: findCoin.memo,
        });
        setShowSendModal(true);
    };

    const handleCloseSendModal = () => {
        setShowSendModal(false);
    };

    const handleSendCoin = async (values) => {
        try {
            const withdrawalFunds = await BazerService.WithdrawalFunds(seedPhrase, values.amount, values.recipient, values.currency, values.transferType, values.comment);
            if (withdrawalFunds.status === 'OK') {
                Toast.show({
                    type: 'success',
                    text1: withdrawalFunds.data.txID,
                });
            } else {
                Toast.show({
                    type: 'error',
                    text1: withdrawalFunds.error.translationError,
                });
            }
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: "Ошибка отправки!",
            });
        }
    };

    const handleHistory = async () => {
        try {
            // Загрузка операций пользователя
            const response = await BazerService.getUserOperations(seedPhrase);
            setUserOperations(response.data);
            setShowHistoryModal(true);
        } catch (error) {
            console.error('Ошибка загрузки операций:', error);
            Toast.show({
                type: 'error',
                text1: 'Не удалось загрузить историю операций',
            });
        }
    };

    const renderCoinItem = ({ item }) => {
        return (
            <View style={styles.coinItem}>
                <View style={styles.coinDetails}>
                    <Text style={styles.coinName}>{item.name}</Text>
                    <Text style={styles.coinBalance}>Баланс: {item.balance}</Text>
                </View>
                <View style={styles.coinButton}>
                    <Button style={styles.replenishButton} title="Пополнить" onPress={() => handleReplenish(item.name)} />
                    <Button style={styles.sendButton} title="Отправить" onPress={() => handleSend(item.name)} />
                </View>
            </View>
        );
    };

    if (loading) return (<Loader />);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>Баланс:</Text>
                <Button title="Обновить" onPress={refreshData} />
                <Button title="История" onPress={handleHistory} /> 
            </View>
            <FlatList
                data={coinsData}
                renderItem={renderCoinItem}
                keyExtractor={item => item.fullName}
            />

            <ReplenishModal visible={showReplenishModal} onClose={handleCloseReplenishModal} replenishInfo={replenishInfo} />
            <SendModal
                visible={showSendModal}
                onClose={handleCloseSendModal}
                seedPhrase={seedPhrase}
                currency={replenishInfo.coin.toLowerCase()}
                handleSendCoin={handleSendCoin} />
            <HistoryModal
                visible={showHistoryModal}
                onClose={() => setShowHistoryModal(false)}
                operations={userOperations}
            />
            <Toast style={{ zIndex: 9999 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: 20,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    coinItem: {
        marginBottom: 15,
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        borderRadius: 5,
    },
    coinDetails: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    coinButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    sendButton: {
        flex: 1,
        marginLeft: 5,
    },
    replenishButton: {
        flex: 1,
        marginRight: 5
    },
});