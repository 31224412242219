import React, { useState, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { StyleSheet, View, Text, FlatList, Image, TouchableOpacity } from 'react-native';
import BazerService from '../api/bazer/BazerService';
import ProductDetailsModal from '../component/marketplace/ProductDetailsModal';
import SellModal from '../component/marketplace/SellModal';
import Loader from '../component/Loader';
import StorageService from '../service/StorageService';
import FilterModal from '../component/marketplace/FilterModal';
import Svg, { Path } from 'react-native-svg';

const MarketplaceScreen = ({ seedPhrase }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sellModalVisible, setSellModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalVisibleDetails, setModalVisibleDetails] = useState(false);
    const [userId, setUserId] = useState('');
    const [showMyItems, setShowMyItems] = useState(false); // Состояние для переключения между моими и общими объявлениями
    const [filterModalVisible, setFilterModalVisible] = useState(false); // Модальное окно для фильтра

    const categories = ["staking-share"];

    const fetchItems = async () => {
        try {
            setLoading(true);
            const response = await BazerService.ListSell(seedPhrase);

            const filteredItems = showMyItems
                ? response.data.filter(item => item.userId === userId) // Фильтрация по userId
                : response.data;

            const finalItems = selectedCategory
                ? filteredItems.filter(item => item.type === selectedCategory)
                : filteredItems;

            setItems(finalItems);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserId = async () => {
        try {
            const userId = await StorageService.getData('userId');
            setUserId(userId);
        } catch (error) {
            console.error('Error fetching userId:', error);
        }
    };

    useFocusEffect(
        useCallback(() => {
            fetchItems();
            fetchUserId();
        }, [showMyItems, selectedCategory])
    );

    const openSellModal = () => {
        setSellModalVisible(true);
    };

    const closeSellModal = () => {
        setSellModalVisible(false);
    };

    const openFilterModal = () => {
        setFilterModalVisible(true); // Открываем модальное окно фильтра
    };

    const closeFilterModal = () => {
        setFilterModalVisible(false); // Закрываем модальное окно фильтра
    };

    const handleSellItem = async (values) => {
        try {
            const sellItemResponse = await BazerService.createAdvertisement(seedPhrase, values);
            if (sellItemResponse.status === 'OK') {
                Toast.show({
                    type: 'success',
                    text1: 'Товар успешно выставлен на продажу!',
                });
                fetchItems();
            } else {
                Toast.show({
                    type: 'error',
                    text1: sellItemResponse.error.message,
                });
            }
        } catch (error) {
            console.error('Error selling item:', error);
        } finally {
            closeSellModal();
        }
    };

    const handleBuyProduct = async (productId) => {
        try {
            const buyProductResponse = await BazerService.BuyProduct(seedPhrase, productId);
            if (buyProductResponse.status === 'OK') {
                Toast.show({
                    type: 'success',
                    text1: 'Перейдите в bazerbot для дальнейшего совершения сделки!',
                });
                fetchItems();
            } else {
                Toast.show({
                    type: 'error',
                    text1: buyProductResponse.error.message,
                });
            }
        } catch (error) {
            console.error('Error buying product:', error);
        } finally {
            closeModalDetails();
        }
    };

    const handleItemPress = (item) => {
        setSelectedItem(item);
        setModalVisibleDetails(true);
    };

    const closeModalDetails = () => {
        setSelectedItem(null);
        setModalVisibleDetails(false);
    };

    return (
        <View style={styles.container}>
            {/* Используем FilterModal компонент */}
            <FilterModal
                visible={filterModalVisible}
                onClose={closeFilterModal}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                showMyItems={showMyItems}
                setShowMyItems={setShowMyItems}
            />

            {loading ? <Loader /> : (
                <FlatList
                    data={items}
                    renderItem={({ item }) => (
                        <TouchableOpacity onPress={() => handleItemPress(item)}>
                            <View style={styles.item}>
                                <Image source={{ uri: item.imageUrl }} style={styles.itemImage} />
                                <View style={styles.itemDetails}>
                                    <Text style={styles.itemName}>{item.name}</Text>
                                </View>
                                <Text style={styles.itemPrice}>
                                    {item.price} {item.currency}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    )}
                    keyExtractor={(item) => item.productId}
                />
            )}

            <View style={styles.buttonContainer}>
                {/* Кнопка для открытия фильтра */}
                <TouchableOpacity onPress={openFilterModal} style={styles.filterButton}>
                    <Svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.15">
                            <Path d="M12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5C10 3.89543 10.8954 3 12 3Z" fill="#ffffff" />
                            <Path d="M18 10C19.1046 10 20 10.8954 20 12C20 13.1046 19.1046 14 18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10Z" fill="#ffffff" />
                            <Path d="M6 17C7.10457 17 8 17.8954 8 19C8 20.1046 7.10457 21 6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17Z" fill="#ffffff" />
                        </g>
                        <Path
                            d="M14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5M14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5M14 5H20M10 5L4 5M16 12C16 13.1046 16.8954 14 18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12ZM16 12H4M8 19C8 17.8954 7.10457 17 6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21C7.10457 21 8 20.1046 8 19ZM8 19H20"
                            stroke="#ffffff"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </Svg>
                </TouchableOpacity>

                {/* Кнопка для открытия модального окна продажи */}
                <TouchableOpacity style={styles.fab} onPress={openSellModal}>
                    <Svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7Z"
                                stroke="#ffffff" strokeWidth="2"
                            />
                            <path
                                d="M16.2739 11.1377C16.6644 10.7472 16.6644 10.114 16.2739 9.7235L14.4823 7.9319C14.0918 7.54137 13.4586 7.54138 13.0681 7.9319L8.96106 12.0389L8.34768 15.7477C8.3365 15.8154 8.39516 15.874 8.4628 15.8627L12.1669 15.2448L16.2739 11.1377Z"
                                stroke="#ffffff" strokeWidth="2"
                            />
                        </g>
                    </Svg>
                </TouchableOpacity>
            </View>

            {/* Модальное окно для продажи */}
            <SellModal
                visible={sellModalVisible}
                onClose={closeSellModal}
                onSell={handleSellItem}
                categories={categories}
            />

            {/* Модальное окно для отображения деталей */}
            <ProductDetailsModal
                modalVisible={modalVisibleDetails}
                selectedItem={selectedItem}
                closeModal={closeModalDetails}
                buyProduct={handleBuyProduct}
                userId={userId}
            />

            <Toast style={{ zIndex: 9999 }} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f0f0f0',
        padding: 20,
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 20,  // Располагаем контейнер внизу
        right: 20,
        flexDirection: 'column', // Располагаем кнопки вертикально
        alignItems: 'center', // Центрируем кнопки по горизонтали
        zIndex: 10,
    },
    filterButton: {
        width: 60,
        height: 60,
        borderRadius: 30,
        backgroundColor: '#007bff',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10, // Отступ между кнопками
    },
    fab: {
        width: 60,
        height: 60,
        borderRadius: 30,
        backgroundColor: '#007bff',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fabText: {
        fontSize: 30,  // Увеличиваем размер шрифта для лучшего центра
        color: 'white',
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 10,
        padding: 10,
    },
    itemImage: {
        width: 50,
        height: 50,
        borderRadius: 8,
        marginRight: 10,
    },
    itemDetails: {
        flex: 1,
    },
    itemName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    itemPrice: {
        fontSize: 14,
        color: '#4CAF50',
        textAlign: 'right',
    },
});
export default MarketplaceScreen;